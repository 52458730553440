import { Configuration, PluginConfigEditorType } from '@wix/comments-ooi-client';

import {
  IMAGE_TYPE,
  VIDEO_TYPE,
  LINK_TYPE,
  HTML_TYPE,
  FILE_UPLOAD_TYPE,
  POLL_TYPE,
  GALLERY_TYPE,
  DIVIDER_TYPE,
  COLLAPSIBLE_LIST_TYPE,
  GIPHY_TYPE,
  TABLE_TYPE,
  VERTICAL_EMBED_TYPE,
  AUDIO_TYPE,
  EMOJI_TYPE,
  CODE_BLOCK_TYPE,
  LINK_PREVIEW_TYPE,
  pluginEmojiViewer,
  pluginCodeBlockViewer,
} from '@wix/ricos';

import {
  pluginVideoLoadable as pluginVideoViewer,
  pluginImageLoadable as pluginImageViewer,
  pluginLinkLoadable as pluginLinkViewer,
  pluginHtmlLoadable as pluginHtmlViewer,
  pluginFileUploadLoadable as pluginFileUploadViewer,
  pluginPollLoadable as pluginPollViewer,
  pluginGalleryLoadable as pluginGalleryViewer,
  pluginDividerLoadable as pluginDividerViewer,
  pluginCollapsibleListLoadable as pluginCollapsibleListViewer,
  pluginGiphyLoadable as pluginGiphyViewer,
  pluginTableLoadable as pluginTableViewer,
  pluginVerticalEmbedLoadable as pluginVerticalEmbedViewer,
  pluginAudioLoadable as pluginAudioViewer,
  pluginLinkPreviewLoadable as pluginLinkPreviewViewer,
} from '@wix/ricos/loadable';

import { WidgetSettings } from './get-widget-settings';
import { isDefined } from '~/ts-utils';

export const getPluginConfig = (settings: WidgetSettings): Configuration['pluginConfig'] => {
  return {
    type: 'async',
    loadEditorPluginsAsync: createEditorPluginLoader(settings),
    plugins: {
      [IMAGE_TYPE]: { viewerModule: pluginImageViewer },
      [VIDEO_TYPE]: { viewerModule: pluginVideoViewer },
      [LINK_TYPE]: { viewerModule: pluginLinkViewer },
      [HTML_TYPE]: { viewerModule: pluginHtmlViewer },
      [FILE_UPLOAD_TYPE]: { viewerModule: pluginFileUploadViewer },
      [POLL_TYPE]: { viewerModule: pluginPollViewer },
      [GALLERY_TYPE]: { viewerModule: pluginGalleryViewer },
      [DIVIDER_TYPE]: { viewerModule: pluginDividerViewer },
      [COLLAPSIBLE_LIST_TYPE]: { viewerModule: pluginCollapsibleListViewer },
      [GIPHY_TYPE]: {
        viewerModule: pluginGiphyViewer,
      },
      [TABLE_TYPE]: { viewerModule: pluginTableViewer },
      [VERTICAL_EMBED_TYPE]: { viewerModule: pluginVerticalEmbedViewer },
      [AUDIO_TYPE]: { viewerModule: pluginAudioViewer },
      [EMOJI_TYPE]: { viewerModule: pluginEmojiViewer },
      [CODE_BLOCK_TYPE]: { viewerModule: pluginCodeBlockViewer },
      [LINK_PREVIEW_TYPE]: { viewerModule: pluginLinkPreviewViewer },
    },
  };
};

const createEditorPluginLoader = (settings: WidgetSettings) => {
  return async (): Promise<PluginConfigEditorType> => {
    const pluginConfig: Record<string, any> = {};

    if (settings.isImagePluginEnabled) {
      const { pluginImage } = await import(
        /* webpackChunkName: "comments-widget-plugin-image" */
        './plugins/plugin-image'
      );
      pluginConfig[IMAGE_TYPE] = {
        editorModule: pluginImage,
      };
    }
    if (settings.isAddingLinksEnabled) {
      const { pluginLink } = await import(
        /* webpackChunkName: "comments-widget-plugin-link" */
        './plugins/plugin-link'
      );
      pluginConfig[LINK_TYPE] = {
        editorModule: pluginLink,
      };
    }
    if (settings.isVideoPluginEnabled || settings.isYouTubePluginEnabled) {
      const { pluginVideo, videoButtonsTypes } = await import(
        /* webpackChunkName: "comments-widget-plugin-video" */
        './plugins/plugin-video'
      );
      pluginConfig[VIDEO_TYPE] = {
        editorModule: pluginVideo,
        editorConfig: {
          exposeButtons: [
            settings.isVideoPluginEnabled ? videoButtonsTypes.video : undefined,
            settings.isYouTubePluginEnabled ? videoButtonsTypes.youTube : undefined,
          ].filter(isDefined),
        },
      };
    }
    if (settings.isGiphyPluginEnabled) {
      const { pluginGiphy } = await import(
        /* webpackChunkName: "comments-widget-plugin-giphy" */
        './plugins/plugin-giphy'
      );
      pluginConfig[GIPHY_TYPE] = {
        editorModule: pluginGiphy,
      };
    }
    if (settings.isDividerPluginEnabled) {
      const { pluginDivider } = await import(
        /* webpackChunkName: "comments-widget-plugin-divider" */
        './plugins/plugin-divider'
      );
      pluginConfig[DIVIDER_TYPE] = {
        editorModule: pluginDivider,
      };
    }
    if (settings.isGalleryPluginEnabled) {
      const { pluginGallery } = await import(
        /* webpackChunkName: "comments-widget-plugin-gallery" */
        './plugins/plugin-gallery'
      );
      pluginConfig[GALLERY_TYPE] = {
        editorModule: pluginGallery,
      };
    }
    if (settings.isSpotifyPluginEnabled || settings.isSoundCloudPluginEnabled) {
      const { pluginAudio, audioButtonsTypes } = await import(
        /* webpackChunkName: "comments-widget-plugin-audio" */
        './plugins/plugin-audio'
      );
      pluginConfig[AUDIO_TYPE] = {
        editorModule: pluginAudio,
        editorConfig: {
          exposeButtons: [
            settings.isSpotifyPluginEnabled ? audioButtonsTypes.spotify : undefined,
            settings.isSoundCloudPluginEnabled ? audioButtonsTypes.soundCloud : undefined,
          ].filter(isDefined),
        },
      };
    }
    if (settings.isTablePluginEnabled) {
      const { pluginTable } = await import(
        /* webpackChunkName: "comments-widget-plugin-table" */
        './plugins/plugin-table'
      );
      pluginConfig[TABLE_TYPE] = {
        editorModule: pluginTable,
      };
    }
    if (settings.isPollPluginEnabled) {
      const { pluginPoll } = await import(
        /* webpackChunkName: "comments-widget-plugin-social-polls" */
        './plugins/plugin-social-polls'
      );
      pluginConfig[POLL_TYPE] = {
        editorModule: pluginPoll,
      };
    }
    if (settings.isHtmlCodePluginEnabled) {
      const { pluginHtml } = await import(
        /* webpackChunkName: "comments-widget-plugin-html" */
        './plugins/plugin-html'
      );
      pluginConfig[HTML_TYPE] = {
        editorModule: pluginHtml,
      };
    }
    if (settings.isCollapsibleListPluginEnabled) {
      const { pluginCollapsibleList } = await import(
        /* webpackChunkName: "comments-widget-plugin-collapsible-list" */
        './plugins/plugin-collapsible-list'
      );
      pluginConfig[COLLAPSIBLE_LIST_TYPE] = {
        editorModule: pluginCollapsibleList,
      };
    }
    if (settings.isFileUploadPluginEnabled) {
      const { pluginFileUpload } = await import(
        /* webpackChunkName: "comments-widget-plugin-file-upload" */
        './plugins/plugin-file-upload'
      );
      pluginConfig[FILE_UPLOAD_TYPE] = {
        editorModule: pluginFileUpload,
      };
    }
    if (
      settings.isStoresPluginEnabled ||
      settings.isEventsPluginEnabled ||
      settings.isBookingsPluginEnabled
    ) {
      const { pluginVerticalEmbed, verticalEmbedProviders } = await import(
        /* webpackChunkName: "comments-widget-plugin-vertical-embed" */
        './plugins/plugin-vertical-embed'
      );
      pluginConfig[VERTICAL_EMBED_TYPE] = {
        editorModule: pluginVerticalEmbed,
        editorConfig: {
          exposeEmbedButtons: [
            settings.isStoresPluginEnabled ? verticalEmbedProviders.product : undefined,
            settings.isEventsPluginEnabled ? verticalEmbedProviders.event : undefined,
            settings.isBookingsPluginEnabled ? verticalEmbedProviders.booking : undefined,
          ].filter(isDefined),
        },
      };
    }
    if (settings.isEmojiPluginEnabled) {
      const { pluginEmoji } = await import(
        /* webpackChunkName: "comments-widget-plugin-emoji" */
        './plugins/plugin-emoji'
      );
      pluginConfig[EMOJI_TYPE] = {
        editorModule: pluginEmoji,
      };
    }
    if (settings.isCodeBlockPluginEnabled) {
      const { pluginCodeBlock } = await import(
        /* webpackChunkName: "comments-widget-plugin-code-block" */
        './plugins/plugin-code-block'
      );
      pluginConfig[CODE_BLOCK_TYPE] = {
        editorModule: pluginCodeBlock,
      };
    }
    if (
      settings.isInstagramPluginEnabled ||
      settings.isTwitterPluginEnabled ||
      settings.isTikTokPluginEnabled
    ) {
      const { pluginLinkPreview, LinkPreviewProviders } = await import(
        /* webpackChunkName: "comments-widget-plugin-link-preview" */
        './plugins/plugin-link-preview'
      );
      pluginConfig[LINK_PREVIEW_TYPE] = {
        editorModule: pluginLinkPreview,
        editorConfig: {
          enableEmbed: true,
          exposeEmbedButtons: [
            settings.isInstagramPluginEnabled ? LinkPreviewProviders.Instagram : undefined,
            settings.isTwitterPluginEnabled ? LinkPreviewProviders.Twitter : undefined,
            settings.isTikTokPluginEnabled ? LinkPreviewProviders.TikTok : undefined,
          ].filter(isDefined),
        },
      };
    }

    return pluginConfig;
  };
};
